/* Estilos para el modal */
.modal {
    position: fixed; /* Para que el modal esté fijo sobre la pantalla */
    top: 50%; /* Centra verticalmente el modal */
    left: 50%; /* Centra horizontalmente el modal */
    transform: translate(-50%, -50%); /* Asegura el centrado */
    z-index: 1000; /* Asegura que el modal esté encima de otros elementos */
    background-color: white; /* Fondo blanco para el contenido del modal */
    padding: 20px; /* Espaciado interno del modal */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para darle profundidad */
    width: 80%; /* Ancho del modal */
    max-width: 500px; /* Ancho máximo */
    overflow: auto; /* Permite hacer scroll si el contenido es demasiado grande */
  }
  
  /* Estilos para el fondo oscuro detrás del modal */
  .modal-overlay {
    position: fixed; /* Abarca toda la pantalla */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
    z-index: 999; /* Debe estar detrás del modal pero encima del resto del contenido */
  }
  
  /* Estilos para los botones */
  .modal-close-btn {
    position: absolute; /* Botón de cierre flotante en la esquina */
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
  }
  
  .modal-close-btn:hover {
    color: #000;
  }
  
  /* Ajustes para dispositivos móviles */
  @media (max-width: 768px) {
    .modal {
      width: 90%;
    }
  }
  