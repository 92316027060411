.MenuContainer {
    display: flex;
    flex-direction: column;
    background-color: #1a1a1a;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.MenuBannerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
}

.menuContainer {
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 50%;
    justify-content: center;
    gap: 1em;
}
.logout{
    margin-top: 1.5em;
}

.button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 8px 22px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}