.PlaysViewContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;
    gap: 1em;
    background-color: #1a1a1a;
    height: calc(100vh - 1em);
    width: calc(100vw - 2em);
    color: rgb(255, 255, 255);
}

.PlaysViewBackButton {
    width: 100%;
    height: 5%;
}

.PlaysViewButtonBack {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 8px 22px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}

.PlaysViewButtonBack:disabled {
    background-color: rgb(108, 117, 125, 0.75);
    cursor: default;
}

.PlaysViewButtonBack:hover:enabled {
    background-color: rgb(218, 37, 153, 0.75);
}

.PlaysViewButtonBack:active:enabled {
    background-color: rgb(218, 37, 153);
}

.PlaysViewPlaysContainer {
    display: flex;
    gap: 0.5em;
    width: 100%;
    height: 85%;
}

.PlaysViewPlays {
    width: calc(55% - 0.25em);
    display: flex;
    /* required */
    flex-flow: row nowrap;
    box-sizing: border-box;
    /* required */
}

.PlaysViewPlayImageContainer {
    box-sizing: border-box;
    /* required */
    width: 100%;
    height: 100%;
    /* required */
    flex: none;
}

.PlaysViewPlayImage {
    width: 100%;
    height: 100%;
}

.PlaysViewPlayPlayersContainer {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #FF0000;
    font-size: "6em";
    width: 100%;
    height: 100%;
    margin-left: -100%;
}

.PlaysViewHandlePlays {
    display: flex-start;
    width: calc(50% - 0.25em);
    justify-content: center;
}

.CenterMarkTeam {
    width: 16%;
    height: 16%;
    position: absolute;
    margin-left: 42%;
    margin-top: 31.25%;
}

.PlaysViewSavePlayButton {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 8px 22px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 75%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}

.PlaysViewSavePlayButton:disabled {
    background-color: rgb(108, 117, 125, 0.75);
    cursor: default;
}

.PlaysViewSavePlayButton:hover:enabled {
    background-color: rgb(218, 37, 153, 0.75);
}

.PlaysViewSavePlayButton:active:enabled {
    background-color: rgb(218, 37, 153);
}
.WarningMessagePlaysView{
    max-width: 90%;
    text-align: center;
    padding: 0.5em;
    border-radius: 0.5em;
    border: 0.1em solid #FF0000;
    background-color: #ff00006b;
;
}