.formPlayerModal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.formPlayerModalContent {
    background-color: #1a1a1a;
    color: #fff;
    margin: 1.5% auto;
    padding: 1.5em;
    border: 1px solid #88888818;
    border-radius: 0.5em;
    height: 85%;
    width: 90%;
}

/* The Close Button */
.formPlayerModalClose {
    color: #aaa;
    float: right;
    font-size: 2em;
    font-weight: bold;
}

.formPlayerModalClose:hover,
.formPlayerModalClose:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.formPlayerModalTitle {
    font-weight: 500;
    margin: 0;
    padding: 0 0 0.1em 0;
    font-size: 1.75em;
    border-bottom: 1px solid #fff;
}

.formPlayerModalContainer {
    width: 100%;
    height: 90%;
    display: column;
    justify-content: center;
    align-items: center;
}

.formPlayerForm {
    width: 100%;
    height: 100%;
    margin-top: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formPlayerInputContainer {
    display: flex;
    width: 100%;
    height: 15%;
    gap: 1.5em;
    margin-bottom: 1.25em;
}

.formPlayerInputLabel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    line-height: 2em;
}

.formPlayerInputLabel>input {
    display: flex;
    width: 95%;
    height: 100%;
    border-radius: 0.25em;
    text-align: start;
    font: 1em sans-serif;
    border: 1px solid #fff;
    padding: 0.25em;
    background-color: #1a1a1a;
    color: #fff;
}

.formPlayerInputLabel>select {
    width: 100%;
    min-height: 60%;
    border-radius: 0.25em;
    font: 1em sans-serif;
    border: 1px solid #fff;
    background-color: #1a1a1a;
    color: #fff;
}

.formPlayerButtonSubmit {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    text-transform: uppercase;
    padding: 6px 16px;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
}

.formPlayerButtonSubmit:hover {
    cursor: pointer;
    background-color: rgb(218, 37, 153, 0.75);
}

.formPlayerButtonSubmit:disabled {
    cursor: default;
    background-color: rgb(218, 37, 153, 0.25);
}

.formPlayerButtonSubmit:enabled:active {
    background-color: rgb(218, 37, 153);
}

.formPlayerAddCustoms {
    display: flex;
    width: 5%;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    margin-left: -1.25em;
    margin-right: -0.5em;
    fill: #fff;
}

.formPlayerAddCustoms svg {
    cursor: pointer;
}

.formPlayerLabel {
    color: #fff;
    font-size: 1.125rem;
    margin-bottom: 0.25em;
}