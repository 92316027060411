.FootballSessionContainer {
  display: flex;
  flex-direction: row;
  background-color: #1a1a1a;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.sessionActions {
  width: 50%;
  height: 100%;
  padding: 0 0.25em;
}

.sessionSettings {
  width: 50%;
  height: 100%;
  padding: 0 0.25em;
}

.sessionViewContainer {
  width: 100%;
  height: 70%;
}

.FootballSessionButton {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.75;
  text-transform: uppercase;

  padding: 8px 22px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 100%;
  color: rgb(255, 255, 255);
  background-color: rgb(218, 37, 153);
  gap: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 1em;
  fill: #fff;
}

.FootballSessionButton:disabled {
  background-color: rgb(108, 117, 125, 0.75);
  cursor: default;
}

.FootballSessionButton:hover:enabled {
  background-color: rgb(218, 37, 153, 0.75);
}

.FootballSessionButton:active:enabled {
  background-color: rgb(218, 37, 153);
}

.sessionWebcamContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
}

.sessionSelectTest {
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  line-height: 1em;
  gap: 0;
}

.selectorTypeTest {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  font-weight: 300;
  line-height: 0em;
  margin: 0;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.distractorsSelect {
  margin-left: 2em;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 1em;
}

.distractorsSelect> :nth-child(2) {
  height: 50%;
}

.sessionInfo {
  width: 100%;
  height: 45%;
  color: #fff;
}

.sessionPlaysInfo {
  display: grid;
  grid-template-columns: auto auto;
  grid-row: auto auto;
  gap: 0.5em;
  padding: 0.5em;
  text-align: center;
  align-items: center;
}

.sessionPlaysValues {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.sessionPlaysValues input {
  height: 45%;
  width: 45%;
  align-self: center;
}

.sessionSequenceButtons {
  display: flex;
  flex-direction: row;
  gap: 10em;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  overflow: hidden;
}

.sessionGenerateSequence {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.75em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 8px 10px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  color: rgb(255, 255, 255);
  background-color: rgb(218, 37, 153);
  gap: 0.5em;
  fill: #fff;
  overflow: hidden;
}

.sessionGenerateSequence:disabled {
  background-color: rgb(108, 117, 125, 0.75);
  cursor: default;
}

.sessionGenerateSequence:hover:enabled {
  background-color: rgb(218, 37, 153, 0.75);
}

.sessionGenerateSequence:active:enabled {
  background-color: rgb(218, 37, 153);
}

.sessionOpenPlayerWindow {
  overflow: hidden;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.75em;
  text-transform: uppercase;
  padding: 8px 10px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  color: rgb(255, 255, 255);
  background-color: rgb(218, 37, 153);
  gap: 0.5em;
  fill: #fff;
}

.sessionOpenPlayerWindow:disabled {
  background-color: rgb(108, 117, 125, 0.75);
  cursor: default;
}

.sessionOpenPlayerWindow:hover:enabled {
  background-color: rgb(218, 37, 153, 0.75);
}

.sessionOpenPlayerWindow:active:enabled {
  background-color: rgb(218, 37, 153);
}

.sessionRandomSeed {
  display: flex;
  flex-direction: row;
  margin-right: -1em;
  padding: 0;
  max-height: 20%;
  max-width: 15%;
}

.sessionActualInfo {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  line-height: 3em;
  padding: 0 1em;
  color: #FFF;
}

.sessionSequenceContainer {
  display: flex;
  justify-content: center;
}

.sessionSequence {
  width: 100%;
  font-size: 1.25em;
  line-height: 1.25em;
  background: none;
  border: 0.05em solid #FFF;
  border-radius: 0.5em;
  color: #fff;
  padding: 0.05em;
  text-align: center;
}

.actionsButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2em;
  padding: 1em 0;
}

.videoActionsButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em
}

.buttonActionsVideo {
  background-color: rgb(218, 37, 153);
  color: rgb(255, 255, 255);
  border-color: rgb(108, 117, 125);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  gap: 0.5em;
  fill: #fff
}

.buttonActionsVideo:hover:enabled {
  background-color: rgb(218, 37, 153, 0.75);
  cursor: pointer;
}

.buttonActionsVideo:active:enabled {
  background-color: rgb(218, 37, 153);
}

.buttonActionsVideo:disabled {
  background-color: rgb(108, 117, 125, 0.75);
  cursor: default;
}

.sessionPlayer {
  width: 95%;
}

.sessionPlayerPlayer {
  line-height: 2.25em;
  font-size: 1.75em;
}

.sessionPlayerSelect {
  width: 50%;
  height: 2em;
}

.sessionPlayerSelectContainer {
  gap: 2em;
  display: flex;
}

.sessionPlayerConfigButtons {
  fill: #da2599;
  display: flex;
  gap: 0.75em;
}

.sessionPlayerConfigButtons button:hover {
  cursor: pointer;
  fill: rgb(218, 37, 153, 0.75);
}

.sessionPlayerConfigButtons button:active {
  fill: rgb(218, 37, 153);
}

.sessionPlayerConfigButtons button:disabled {
  fill: rgb(108, 117, 125, 0.75);
  cursor: default;
}

.sessionPlayerConfigButtons button {
  background: none;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.playsTestSelect {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 1em;
}

.playsTestSelect> :nth-child(2) {
  height: 50%;
}