.ManagementAccountContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;
    gap: 1em;
    background-color: #1a1a1a;
    height: calc(100vh - 1em);
    width: calc(100vw - 2em);
    color: rgb(255, 255, 255);
}

.ManagementAccountContent {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.ManagementAccountLeft {
    display: flex;
    width: 80vw;
}

.ManagementAccountRight {
    display: flex;
    flex-direction: column;
    width: 40vw;
    gap: 1em;
}

.ManagementAccountRight p {
    margin: 0 0 .5em 0;
    padding: 0;
}

.ManagementAccountBackButton {
    width: 100%;
    height: 5%;
}

.ManagementAccountButtonBack {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 8px 22px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}

.ManagementAccountButtonBack:disabled {
    background-color: rgb(108, 117, 125, 0.75);
    cursor: default;
}

.ManagementAccountButtonBack:hover:enabled {
    background-color: rgb(218, 37, 153, 0.75);
}

.ManagementAccountButtonBack:active:enabled {
    background-color: rgb(218, 37, 153);
}

.ManagementAccountButton {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    padding: 8px 22px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}

.ManagementAccountButton:disabled {
    background-color: rgb(108, 117, 125, 0.75);
    cursor: default;
}

.ManagementAccountButton:hover:enabled {
    background-color: rgb(218, 37, 153, 0.75);
}

.ManagementAccountButton:active:enabled {
    background-color: rgb(218, 37, 153);
}


.ManagementAccountImageContainer {
    box-sizing: border-box;
    /* required */
    width: 100%;
    height: 90%;
    /* required */
    flex: none;
}

.PlaysViewPlayImage {
    width: 100%;
    height: 90%;
}

.ManagementAccountPlayersContainer {
    display: flex;
    width: 100%;
    height: 90%;
    margin-top: -77.5%;
}

.ManagementAccountTable {
    width: 100%;
}

.ManagementAccountTitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(255, 255, 255);
    margin: .5em 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

/* Estilos CSS para la tabla */
.table-container {
    height: 67.5vh;
    margin-bottom: 1em;
    /* Altura máxima para mostrar el scroll */
    overflow-y: auto;
    /* Agrega scroll vertical al tbody */
    text-align: center;
    position: relative;

}

.table-container::-webkit-scrollbar {
    display: none;
    /* Ocultar scroll */
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: none;
    color: white;
    table-layout: fixed;
    /* Fija el ancho de la tabla */
}

.custom-table th,
.custom-table td {
    border: 1px solid white;
    padding: 0.5em;
}

.custom-table th {
    background-color: rgb(0, 0, 0, 0.75);
}

.custom-table .table-row {
    background-color: #1a1a1a;
}

.custom-table .table-header {
    background-color: rgb(0, 0, 0, 0.75);
}

.ManagementAccountEditButton {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    fill: white;
}

.ManagementAccountEditButton:active:enabled {
    fill: rgb(255, 255, 255, 0.75);
}

.ManagementAccountEditButton:hover:enabled {
    cursor: pointer;
    fill: rgb(255, 255, 255, 0.75);
}

.ManagementAccountEditButton:disabled {
    fill: rgba(55, 55, 55, 0.75);
    cursor: default;
}

.ManagementAccountShoppingButton {
    width: 10%;
    height: 10%;
    justify-items: center;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    cursor: pointer;
    padding: 0;
    margin: 2.5em 0 0 0;
}

.ManagementAccountShoppingButton > * {
    fill: rgb(218, 37, 153);
    width: max-content;
    height: max-content;
}
.ManagementAccountShoppingButton:hover > * {
    fill: rgb(218, 37, 153, 0.75);
}
.ManagementAccountShoppingButton:active > * {
    fill: rgb(218, 37, 153);
}
.ManagementAccountShoppingButton[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    transform: translateY(-150%) translateX(-25%);
    padding: 0.5em 1em;
    background: #000;
    color: #fff;
    font-size: 0.8em;
    border-radius: 0.5em;
    pointer-events: none;
}
.ManagementAccountModalContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
}

.ManagementAccountCartContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1a1a1a;
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 80%;
    color: white;
}

.ManagementAccountCartContainer .SelectPlan {
    display: flex;
    flex-direction: column;
    gap: 1em;
}