.AnalizeSessionContainer {
    display: flex;
    flex-direction: column;
    background-color: #1a1a1a;
    color: #fff;
    height: 100vh;
    width: 100vw;
}

.AnalizeSessionVideoContainer {
    display: flex;
    flex-direction: row;
    height: 40%;
    width: 100%;
}

.AnalizeSessionMarksContainer {
    display: flex;
    flex-direction: row;
    height: 35%;
    width: 100%;
}

.AnalizeSessionMarksControlContainer {
    display: flex;
    flex-direction: row;
    height: 25%;
    width: 100%;
}

.AnalizeSessionVideoTitle {
    width: auto;
}

.AnalizeSessionVideoInfoSessionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5em;
    width: 35%;
}

.AnalizeSessionVideoCentralFrameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100%;
}

.AnalizeSessionVideoFramesCurrentInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1em;
    width: 35%;

}

.AnalizeSessionVideoCentralFrameControl {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    width: 100%;
}

.AnalizeSessionVideoInfoSession {
    display: flex;
    flex-direction: column;
    gap: 0.1em;
}

.AnalizeSessionVideoFramesCurrentInfo {
    display: flex;
    flex-direction: column;

}

.AnalizeSessionVideoCentralFrame {
    display: flex;
    flex-direction: column;
    width: 70%;
}
.AnalizeSessionVideoCentralFrame svg {
    justify-self: center;
    align-self:center;
    z-index: 1;
}

.AnalizeSessionVideoInfoSessionFrames {
    display: flex;
    flex-direction: row;
}

.AnalizeSessionVideoFramesCurrentInfoFrames {
    display: flex;
    flex-direction: row;
}

.AnalizeSessionVideoFrame {
    display: flex;
    flex-direction: column;
}
.AnalizeSessionVideoFrame svg{
    justify-self: center;
    align-self:center;
    z-index: 1;
}

.AnalizeSessionVideoFrameLabelsInputs {
    display: flex;
    gap: 0;
    width: 100%;
}

.AnalizeSessionVideoFrameLabelInput {
    display: flex;
    flex-direction: column;
    width: 50%;


}

.AnalizeSessionVideoCentralFrameButton {
    background: none;
    border: none;
    fill: #fff;
}

.AnalizeSessionVideoCentralFrameButton:hover {
    fill: rgb(255, 255, 255, 0.5);
    cursor: pointer;
}

.AnalizeSessionVideoCentralFrameButton:active {
    fill: rgb(255, 255, 255);
}

.AnalizeSessionVideoCentralFrameButton[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    transform: translateY(-100%) translateX(-50%);
    padding: 0.5em 1em;
    background: #000;
    color: #fff;
    font-size: 0.8em;
    border-radius: 0.5em;
    pointer-events: none;
}

.AnalizeSessionStepInput {
    background: none;
    border: 0.1em solid #fff;
    border-radius: 0.5em;
    width: 10%;
    text-align: center;
    color: #fff;
}

.AnalizeSessionVideo {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.AnalizeSessionVideoCentral {
    display: flex;
    flex-direction: column;
    width: 95%;
}

.AnalizeSessionMarks {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
}

.AnalizeSessionMarksTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.AnalizeSessionCurrentPlay {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 0 0.5em;
}

/* Estilos CSS para la tabla */
.table-containerA {
    max-height: 35vh;
    /* Altura máxima para mostrar el scroll */
    overflow-y: auto;
    /* Agrega scroll vertical al tbody */
    text-align: center;
    position: relative;

}

.table-container::-webkit-scrollbar {
    display: none;
    /* Ocultar scroll */
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: none;
    color: white;
    table-layout: fixed;
    /* Fija el ancho de la tabla */
}

.custom-table th,
.custom-table td {
    border: 1px solid white;
    padding: 0.5em;
}

.custom-table th {
    background-color: rgb(0, 0, 0, 0.75);
}

.custom-table .table-row {
    background-color: #1a1a1a;
}

.custom-table .table-header {
    background-color: rgb(0, 0, 0, 0.75);
}
.table-header[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    transform: translateY(-100%) translateX(-50%);
    padding: 0.5em 1em;
    background: #000;
    color: #fff;
    font-size: 0.8em;
    border-radius: 0.5em;
    pointer-events: none;
}

.AnalizeSessionCurrentPlayImg {
    width: 75%;
}

.AnalizeSessionInputFrame {
    width: 30%;
    background: none;
    border: none;
    color: #fff
}

.AnalizeSessionInputFrameInfo {
    width: 15%;
    background: none;
    border: none;
    color: #fff
}

.AnalizeSessionInputInfo {
    width: 15%;
    background: none;
    border: none;
    color: #fff;
    font: 1em sans-serif;
    overflow: hidden;
}

.AnalizeSessionMarksControlButtons {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 40%;
    padding: 0.5em;
}

.AnalizeSessionMarksControlButtons div {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    width: 100%;
}

.AnalizeSessionMarksControlButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 1.75em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 8px 10px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
    overflow: hidden;
}

.AnalizeSessionMarksControlButton:hover:enabled {
    background-color: rgb(218, 37, 153, 0.75);
    cursor: pointer;
}
.AnalizeSessionMarksControlButton:disabled {
    background-color: #6c757d;
    cursor: default;
}

.AnalizeSessionMarksControlButton:active:enabled {
    background-color: rgb(218, 37, 153);
}

.AnalizeSessionMarksControlMetrics {
    width: 59%;
    padding: 0.5em
}

.AnalizeSessionMarksControlMetricsTable {
    width: 100%;
    border-collapse: collapse;
    background-color: none;
    color: white;
    table-layout: fixed;
    text-align: center;
}

.AnalizeSessionMarksControlMetricsTable th,
.AnalizeSessionMarksControlMetricsTable td {
    border: 0.5px solid white;
    line-height: 1em;
    font-size: 0.95em;
}

.AnalizeSessionMarksControlMetricsTable th {

    background-color: rgb(0, 0, 0, 0.75);
}

.AnalizeSessionMarksControlMetricsTable .table-row {
    background-color: #1a1a1a;
}

.AnalizeSessionBackButton {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-size: 1rem;
    line-height: 0.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 4px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}

.AnalizeSessionBackButton:hover {
    cursor: pointer;
    background-color: rgb(218, 37, 153, 0.75);
}

.loading {
    display: inline-block;
    justify-self: center;
    align-self: center;
    position: relative;
    width: 3em;
    height:3em;
    padding:2em;
  }

  input[type="checkbox"] {
    place-content: center;
    justify-self: center;
    align-self: center;
 /* Add if not using autoprefixer */
 -webkit-appearance: none;
 /* Remove most all native input styles */
 appearance: none;
 margin-left: calc(50% - .75em);
 /* For iOS < 15 */
 background-color: var(--form-background);
 /* Not removed via appearance */
 color: white;
 width: 1.5em;
 height: 1.5em;
 border: 0.2em solid white;
 border-radius: 0.25em;
 transform: translateY(-0.075em);

 display: grid;
 place-content: center;
  }
  

  input[type="checkbox"]::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    transform: scale(0);
    transform-origin: bottom left;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: white;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
