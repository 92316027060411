.WebappContainer {
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.WebappBannerContainer {
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
}

.loginContainer {
  font-family: Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;  
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 50%;
  justify-content: center;
}

.loginContainer>input {
  margin-bottom: 2em;
}

.inputs {
  width: 99%;
  align-self: center;
  height: 2.5em;
  border-radius: 0.5em;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.25em 0.5em;
}
.inputs:active{
  outline: 0.2em solid  rgb(218, 37, 153);

}
.label {
  margin: 0;
  color: azure;
  align-content: end;
  margin: 0.2em 0.15em ;
}

.loginButtons {
  align-self: center;
  display: flex;
  gap: 1em;
  width: 75%;
}

.button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 80px;
  padding: 6px 16px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  color: rgb(255, 255, 255);
  background-color: rgb(218, 37, 153);
  cursor: pointer;
}
.button:hover {
  background-color: rgb(218, 37, 153, 0.75);
}
.button:disabled {
  background-color: rgb(200, 200, 200);
  color: rgb(150, 150, 150);
  cursor: not-allowed;
  box-shadow: none;
}
.changePassword{
  font-size: 1rem;
  background:none; 
  border:none;
  width: fit-content;
  align-self: flex-end;
  color: rgb(218, 37, 153);
  margin-top: -1.8em;
  text-decoration: underline;
}
.changePassword:hover{
  color: rgb(218, 37, 153,0.75);
  cursor: pointer;
}

.loading {
  display: inline-block;
  justify-self: center;
  align-self: center;
  position: relative;
  width: 3em;
  height:3em;
}

.languageSwitcher {
  position: fixed;
  top: 1em;
  right: 1em;
  display: flex;
  gap: 1em;
  z-index: 1000; /* Ensure it is above other content */
}

.languageSwitcher button {
  font-family: Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  padding: 6px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: rgb(218, 37, 153);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.languageSwitcher button:hover {
  background-color: rgba(218, 37, 153, 0.75);
}