.ListOfPlaysContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;
    gap: 1em;
    background-color: #1a1a1a;
    height: calc(100vh - 1em);
    width: calc(100vw - 2em);
    color: rgb(255, 255, 255);
}

.ListOfPlaysBackButton {
    width: 100%;
    height: 5%;
}

.ListOfPlaysButtonBack {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 8px 22px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}

.ListOfPlaysButtonBack:disabled {
    background-color: rgb(108, 117, 125, 0.75);
    cursor: default;
}

.ListOfPlaysButtonBack:hover:enabled {
    background-color: rgb(218, 37, 153, 0.75);
}

.ListOfPlaysButtonBack:active:enabled {
    background-color: rgb(218, 37, 153);
}

.ListOfPlaysButton {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    padding: 8px 22px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(218, 37, 153);
    gap: 0.5em;
    fill: #fff;
}

.ListOfPlaysButton:disabled {
    background-color: rgb(108, 117, 125, 0.75);
    cursor: default;
}

.ListOfPlaysButton:hover:enabled {
    background-color: rgb(218, 37, 153, 0.75);
}

.ListOfPlaysButton:active:enabled {
    background-color: rgb(218, 37, 153);
}

.PlaysViewPlaysContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    width: 100%;
    height: 80%;
}

.ListOfPlaysImageContainer {
    box-sizing: border-box;
    /* required */
    width: 100%;
    height: 90%;
    /* required */
    flex: none;
}

.PlaysViewPlayImage {
    width: 100%;
    height: 90%;
}

.ListOfPlaysPlayersContainer {
    display: flex;
    width: 100%;
    height: 90%;
    margin-top: -77.5%;
}
.ListOfPlaysTable{
    width: 50%;
}

/* Estilos CSS para la tabla */
.table-container {
    height: 72.5vh;
    margin-bottom: 1em;
    /* Altura máxima para mostrar el scroll */
    overflow-y: auto;
    /* Agrega scroll vertical al tbody */
    text-align: center;
    position: relative;

}

.table-container::-webkit-scrollbar {
    display: none;
    /* Ocultar scroll */
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: none;
    color: white;
    table-layout: fixed;
    /* Fija el ancho de la tabla */
}

.custom-table th,
.custom-table td {
    border: 1px solid white;
    padding: 0.5em;
}

.custom-table th {
    background-color: rgb(0, 0, 0, 0.75);
}

.custom-table .table-row {
    background-color: #1a1a1a;
}

.custom-table .table-header {
    background-color: rgb(0, 0, 0, 0.75);
}
.ListOfPlaysEditButton{
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    fill: white;
}
.ListOfPlaysEditButton:active:enabled {
    fill: rgb(255, 255, 255, 0.75);
}
.ListOfPlaysEditButton:hover:enabled {
    fill: rgb(255, 255, 255, 0.75);
}
.ListOfPlaysEditButton:disabled {
    fill: rgba(55, 55, 55, 0.75);
    cursor: default;
}