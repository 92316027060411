.sessionPlayerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    /* required */
    flex-flow: row nowrap;
    box-sizing: border-box;
    /* required */
}
.sessionPlayerContainerPlayerView{
    width: 100vw;
    height: 100vh;
    display: flex;
    /* required */
    flex-flow: row nowrap;
    box-sizing: border-box;
}

.sessionPlayerContainer .containerMessages,
.sessionPlayerContainer img {
    box-sizing: border-box;
    /* required */
    width: 100%;
    /* required */
    flex: none;
    /* required */
}

.containerMessages {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF0000;
    font-size: "6em";
    width: 100%;
    height: 100%;
    margin-left: -100%;
    /* required */
}
.containerTeam{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: -100%;
}
.containerAnimation {
    width: 100%;
    height: 100%;
    position: relative;
    margin-left: -100%;
    display: flex;
    /* required */
}