.modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #1a1a1a;
    padding: 1em;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    color: white;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 1em;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
  }
  
  .cancel-button, .save-button {
    padding: 0.5em 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #555;
    color: white;
  }
  
  .save-button {
    background-color: #218838;
    color: white;
  }
  
  .cancel-button:hover, .save-button:hover {
    opacity: 0.9;
  }
  